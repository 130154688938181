import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { AccountsResponse, Qna } from 'services/api/models';
import QnaEditor from 'components/qnaEditor/QnaEditor/QnaEditor';
import { useAppServices } from 'hooks/useAppServices';
import { Alert } from 'contexts/AlertContext';
import { useTranslation } from 'react-i18next';

export const QNA_TAB_TITLE = 'Q&As';

export interface QnaTabProps {
  account: AccountsResponse;
  onUpdate: (queryKeys?: string[], alert?: Alert) => void;
  onError: (error: Error) => void;
}

const QnaTab: React.FC<QnaTabProps> = (props) => {
  const { account, onUpdate, onError } = props;
  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { dataService } = useAppServices();
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'qna'
  });

  /*
   * ************** Hooks *********************
   */
  const addMutation = useMutation({
    mutationFn: async (qna: Qna) => await dataService.uploadQna(qna),
    onSuccess: () =>
      onUpdate(['qnas', 'pages'], {
        severity: 'info',
        message: t('qnaAdded')
      }),
    onError: onError
  });

  const editMutation = useMutation({
    mutationFn: async (qna: Qna) => await dataService.updateQna(qna),
    onSuccess: () =>
      onUpdate(['qnas', 'pages'], {
        severity: 'info',
        message: t('qnaUpdated')
      }),
    onError: onError
  });

  const deleteMutation = useMutation({
    mutationFn: async (qna: Qna) => await dataService.deleteQna(qna),
    onSuccess: () =>
      onUpdate(['qnas', 'pages'], {
        severity: 'info',
        message: t('qnaDeleted')
      }),
    onError: onError
  });

  const isProcessing =
    addMutation.isLoading || editMutation.isLoading || deleteMutation.isLoading;

  /*
   * ************** Render *********************
   */
  return (
    <Box
      sx={{
        mt: theme.spacing(1)
      }}
    >
      <QnaEditor
        account={account}
        isProcessing={isProcessing}
        onSave={(item, edit) =>
          edit ? editMutation.mutate(item) : addMutation.mutate(item)
        }
        onDelete={deleteMutation.mutate}
      />
    </Box>
  );
};

export default QnaTab;
