import { FC, useState } from 'react';
import { Box, Divider, useTheme, Skeleton, Tabs, Tab } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { useAppServices } from 'hooks/useAppServices';
import { AccountsResponse } from 'services/api/models';
import DocumentUploader from 'components/documentStore/DocumentUploader/DocumentUploader';
import DocumentStoreViewer from 'components/documentStore/DocumentStoreViewer/DocumentStoreViewer';
import { getAllowedTagsForUser } from 'util/tags';
import { useAuth } from 'hooks/useAuth';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import { Alert } from 'contexts/AlertContext';
import { useTranslation } from 'react-i18next';

export const DOCUMENT_STORE_TAB_TITLE = 'Documents';

interface DocumentStoreTabProps {
  account: AccountsResponse;
  onUpdate: (queryKeys?: string[], alert?: Alert) => void;
  onError: (error: unknown) => void;
}

const DocumentStoreTab: FC<DocumentStoreTabProps> = (
  props: DocumentStoreTabProps
) => {
  const { account, onUpdate, onError } = props;

  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const { dataService } = useAppServices();
  const { userGroups } = useAuth();
  const { t } = useTranslation('knowledgeBase', {
    keyPrefix: 'documentStore'
  });

  /*
   * ************** State Vars *********************
   */
  const [tab, setTab] = useState(0);

  /*
   * ************** Hooks *********************
   */
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ['files'],
    queryFn: async () => {
      // Get the tags the user has permissions for
      const tags = getAllowedTagsForUser(account, userGroups);
      return await dataService.getFileData(tags);
    }
  });

  /*
   * ************** Helper Function(s) *********************
   */
  const handleTabChanged = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const availableTags = Array.from(
    new Set(account.groupTags.flatMap((groupTag) => groupTag.tags))
  );

  /*
   * ************** Render *********************
   */
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={theme.spacing(60)}
        />
      </Box>
    );
  }

  if (isError) {
    onError(error);
    return <Box sx={{ display: 'flex' }}>{t('errorPage')}</Box>;
  }

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.divider
        }}
      >
        <Tabs
          value={tab}
          onChange={handleTabChanged}
          aria-label="fileUploadTabs"
          textColor={theme.palette.mode === 'light' ? 'primary' : 'inherit'}
          TabIndicatorProps={{
            sx: {
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? theme.palette.text.secondary
                  : ''
            }
          }}
        >
          <Tab label="Text" />
          <Tab label="CSV/JSON" />
        </Tabs>
      </Box>

      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          padding: '5px',
          mt: 4
        }}
      >
        <CustomTabPanel value={tab} index={0}>
          <DocumentUploader
            allowedFileTypes={['.txt', '.md', '.pdf', '.doc', '.docx']}
            tags={availableTags}
            onUpdate={onUpdate}
            onError={onError}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <DocumentUploader
            allowedFileTypes={['.csv', '.json']}
            tags={availableTags}
            onUpdate={onUpdate}
            onError={onError}
          />
        </CustomTabPanel>
      </Box>

      <Divider />

      <DocumentStoreViewer
        files={data}
        tags={availableTags}
        onUpdate={onUpdate}
        onError={onError}
      />
    </>
  );
};

export default DocumentStoreTab;
