import { ApiClient } from './apiClient';
import {
  AccountUpdateRequestBody,
  AccountsResponse,
  GetFileMetadataOptions,
  FileMetadata,
  FilesResponse,
  Qna,
  GetQnasOptions,
  QnasResponse
} from 'services/api/models';
import { protectedResources } from 'config/authConfig';
import { ApiKeyAuthorizationProvider } from './authorizationProvider';

export class DataApiClient extends ApiClient {
  constructor(tenantId: string, apiKey: string) {
    super(
      protectedResources.dataApi.baseUrl,
      new ApiKeyAuthorizationProvider(tenantId, apiKey)
    );
  }

  async getAccount(): Promise<AccountsResponse> {
    const endpoint = `/accounts`;
    return this.get<AccountsResponse>(endpoint);
  }

  async updateAccount(account: AccountUpdateRequestBody): Promise<void> {
    const endpoint = `/accounts`;
    return this.patch(endpoint, {
      requestBody: account
    });
  }

  async getQnas(options?: GetQnasOptions): Promise<QnasResponse> {
    const endpoint = `/qnas`;
    let queryParameters;
    if (options) {
      queryParameters = new URLSearchParams();
      if (options.top) {
        queryParameters.append('top', `${options.top}`);
      }
      if (options.skip) {
        queryParameters.append('skip', `${options.skip}`);
      }
      if (options.tags) {
        for (const tag of options.tags) {
          queryParameters.append('tag', tag);
        }
      }
    }

    return this.get<QnasResponse>(endpoint, {
      queryParameters
    });
  }

  async createQna(qna: Qna) {
    const endpoint = `/qnas`;
    return this.post(endpoint, {
      requestBody: {
        options: {
          wait: true,
          useVectors: true
        },
        qna
      }
    });
  }

  async updateQna(qna: Qna) {
    if (!qna.id) throw new Error('ID required to update Q&A');

    const endpoint = `/qnas/${qna.id}`;
    return this.put(endpoint, {
      requestBody: {
        options: {
          wait: true
        },
        qna
      }
    });
  }

  async deleteQna(qna: Qna) {
    if (!qna.id) throw new Error('ID required to delete Q&A');

    const endpoint = `/qnas/${qna.id}`;
    return this.delete(endpoint);
  }

  async getFile(fileName: string): Promise<Blob> {
    const endpoint = `/files/${fileName}`;
    return this.getBlob(endpoint);
  }

  async getAllFilesMetadata(
    options?: GetFileMetadataOptions
  ): Promise<FilesResponse> {
    const endpoint = `/files/metadata`;
    let queryParameters;
    if (options) {
      queryParameters = new URLSearchParams();
      if (options.includeContent) {
        queryParameters.append('includeContent', `${options.includeContent}`);
      }
      if (options.tags) {
        for (const tag of options.tags) {
          queryParameters.append('tag', tag);
        }
      }
    }

    return this.get<FilesResponse>(endpoint, {
      queryParameters
    });
  }

  async indexFiles(
    files: File[],
    tags: string[],
    descriptions?: Record<string, string>
  ) {
    const endpoint = `/files`;

    // Create the multipart body
    const form = new FormData();
    for (const tag of tags) {
      form.append('tags', tag);
    }

    // Add descriptions for semi-structured files
    const jsonDescriptions: Record<string, string> = {};
    const csvRowDescriptions: Record<string, string> = {};
    files.forEach((file, index) => {
      form.append(`file${index}`, file);

      if (descriptions && Object.hasOwn(descriptions, file.name)) {
        if (file.type === 'application/json') {
          jsonDescriptions[file.name] = descriptions[file.name];
        } else if (file.type === 'text/csv') {
          csvRowDescriptions[file.name] = descriptions[file.name];
        }
      }
    });

    form.append('jsonDescriptions', JSON.stringify(jsonDescriptions));
    form.append('csvRowDescriptions', JSON.stringify(csvRowDescriptions));

    return this.post(endpoint, {
      requestBody: form
    });
  }

  async updateFileMetadata(fileName: string, metadata: FileMetadata) {
    const endpoint = `/files/${fileName}`;
    return this.patch(endpoint, {
      requestBody: {
        options: {
          wait: true
        },
        metadata
      }
    });
  }

  async deleteFile(fileName: string) {
    const endpoint = `/files/${fileName}`;
    return this.delete(endpoint);
  }
}
