import { useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import DOMPurify from 'dompurify';
import { waveform } from 'ldrs';
import AssignmentIcon from '@mui/icons-material/Assignment';

import GroupLogo from '../../../assets/GroupLogo.png';

import { ChatResponse, ChatResponseChunk } from 'services/api/models';
import { HtmlParsedAnswer, parseMessageToHtml } from './ChatAnswerParser';
import { DarkModeColorPalette, LightModeColorPalette } from 'styles/colors';
import { useTranslation } from 'react-i18next';

interface ChatAnswerProps {
  message?: ChatResponse;
  stream?: AsyncGenerator<ChatResponseChunk, void>;
  docChat?: boolean;
  onCitationClicked: (
    type: 'file' | 'qna',
    fileOptions?: {
      filePath: string;
      sourcePath: string;
      page: string;
    }
  ) => void;
  onSupportingContentClicked: () => void;
  onStreamFinished?: (response: ChatResponse) => void;
  onError?: (error: Error) => void;
}

const ChatAnswer: React.FC<ChatAnswerProps> = ({
  message,
  stream,
  docChat,
  onCitationClicked,
  onSupportingContentClicked,
  onStreamFinished,
  onError
}: ChatAnswerProps) => {
  /*
   * ************** Providers **************
   */
  const theme = useTheme();
  const { t } = useTranslation('chat', {
    keyPrefix: 'chat.answer'
  });

  /*
   * ************** State Vars **************
   */
  const answerEndRef = useRef<HTMLDivElement | null>(null);
  const [isThinking, setIsThinking] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [parsedResponse, setParsedResponse] = useState<HtmlParsedAnswer>();
  const [response, setResponse] = useState<ChatResponse>(() => {
    if (message) {
      return message;
    } else {
      return {
        choices: [
          {
            index: 0,
            message: {
              content: '',
              role: 'assistant' as const,
              context: {
                data_points: {},
                followup_questions: []
              },
              session_state: {}
            },
            finish_reason: ''
          }
        ],
        object: 'chat.completion'
      };
    }
  });

  /*
   * ************** Hooks **************
   */
  // Scroll to the bottom of the answer as it's being generated
  const scrollToEnd = () => {
    if (answerEndRef.current) {
      answerEndRef.current.scrollIntoView(false);
    }
  };

  useEffect(() => {
    scrollToEnd();
  }, [parsedResponse]);

  useEffect(() => {
    const setData = async () => {
      if (stream) {
        // Stream the response as it arrives
        setIsGenerating(true);
        setIsThinking(true);
        try {
          for await (const chunk of stream) {
            const nextResponse = response;
            const { content, context, session_state } = chunk.choices[0].delta;
            const finishReason = chunk.choices[0].finish_reason;

            const chunkValue = content ?? '';
            if (chunkValue === '') {
              // Skip chunks with no content
              continue;
            } else {
              // Gather the chunks one-by-one into a single response
              nextResponse.choices[0].finish_reason = finishReason;
              nextResponse.choices[0].message = {
                content: chunkValue,
                role: 'assistant',
                context: context ?? {},
                session_state: session_state ?? {}
              };
            }

            setResponse(nextResponse);
            const parsedMessage = parseMessageToHtml(
              nextResponse.choices[0].message.content,
              nextResponse.choices[0].message.context?.data_points ?? {},
              theme.palette.mode,
              t,
              docChat,
              onCitationClicked
            );
            setParsedResponse(parsedMessage);
            scrollToEnd();

            setIsThinking(false);
          }
        } catch (_error: unknown) {
          const error = _error as Error;
          onError && onError(error);
        } finally {
          onStreamFinished && onStreamFinished(response);
          setIsGenerating(false);
        }
      } else if (message) {
        // Render the entire response since it has finished streaming
        setResponse(message);
        const parsedMessage = parseMessageToHtml(
          message.choices[0].message.content,
          message.choices[0].message.context?.data_points ?? {},
          theme.palette.mode,
          t,
          docChat,
          onCitationClicked
        );
        setParsedResponse(parsedMessage);
        scrollToEnd();
      }
    };

    setData();
  }, []);

  waveform.register();
  const chatMessage = response.choices[0].message;
  const sanitizedHtml = parsedResponse
    ? DOMPurify.sanitize(parsedResponse.answerHtml)
    : undefined;

  /*
   * ************** Render **************
   */
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, mr: 1 }}>
        <img src={GroupLogo} alt="" />
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '8px',
          padding: '10px',
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center',
          flexDirection: 'column',
          mt: 1,
          background:
            theme.palette.mode === 'dark'
              ? '#605E5C'
              : 'rgba(39, 105, 203, 0.08)'
        }}
      >
        {isThinking ? (
          <Box sx={{ padding: '8px' }}>
            <l-waveform
              color={
                theme.palette.mode === 'dark'
                  ? DarkModeColorPalette.textPrimary
                  : LightModeColorPalette.textLink
              }
              stroke={3}
              size={24}
            ></l-waveform>
          </Box>
        ) : (
          <>
            {docChat && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box sx={{ display: 'flex', alignItems: 'end' }}>
                  <Tooltip title={t('supportingContentButtonTooltip')}>
                    <span>
                      <IconButton
                        disabled={
                          isGenerating ||
                          !chatMessage.context?.data_points?.length
                        }
                        onClick={onSupportingContentClicked}
                      >
                        <AssignmentIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Stack>
            )}

            {parsedResponse && sanitizedHtml && (
              <>
                <Box
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    width: '100%',
                    padding: '16px 8px'
                  }}
                >
                  <Box
                    sx={{
                      textAlign: 'start'
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                    ></div>
                  </Box>
                </Box>

                {!!parsedResponse.citations.length && (
                  <Box
                    sx={{
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      padding: '16px 8px',
                      width: '100%'
                    }}
                  >
                    <Typography variant="h3">{t('citations')}</Typography>
                    <List dense>
                      {parsedResponse.citations.map((citation, index) => {
                        const { name, type } = citation;
                        const sourcePath = (parsedResponse.sourceFiles as any)[
                          name
                        ];
                        const page = (parsedResponse.pageNumbers as any)[name];
                        return (
                          <ListItem key={index}>
                            <Link
                              key={index}
                              title={name}
                              sx={{ cursor: 'pointer' }}
                              underline="hover"
                              onClick={() =>
                                onCitationClicked(type, {
                                  filePath: name,
                                  sourcePath,
                                  page
                                })
                              }
                            >
                              {`${++index}. ${name}`}
                            </Link>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </Box>
      <div style={{ scrollMarginBottom: 200 }} ref={answerEndRef} />
    </>
  );
};

export default ChatAnswer;
