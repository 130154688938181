import { useState } from 'react';
import {
  Box,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import QnaTab from './tabs/QnaTab';
import DocumentStoreTab from './tabs/DocumentStoreTab';
import { useAlerts } from 'hooks/useAlerts';
import CustomTabPanel from 'components/CustomTabPanel/CustomTabPanel';
import { useAppServices } from 'hooks/useAppServices';
import { Alert } from 'contexts/AlertContext';
import { useTranslation } from 'react-i18next';

const KnowledgeBasePage: React.FC = () => {
  /*
   * ************** Providers *********************
   */
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { dataService } = useAppServices();
  const { sendAlert } = useAlerts();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('knowledgeBase');

  /*
   * ************** State Vars *********************
   */
  const [tab, setTab] = useState(0);

  /*
   * ************** Hooks *********************
   */
  const {
    data: account,
    error,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['account'],
    queryFn: () => dataService.getAccount(),
    staleTime: 1800000 // 30 minutes
  });

  /*
   * ************** Helper Function(s) *********************
   */
  const handleTabChanged = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleError = (err: unknown) => {
    const error = err as Error;
    console.error('The API operation failed with the following error', error);
    sendAlert({
      severity: 'error',
      message: `${t('alert.updateKnowledgeBaseError')}: ${error.message}`
    });
  };

  const handleUpdate = (queryKeys?: string[], alert?: Alert) => {
    if (alert) {
      sendAlert(alert);
    }

    if (queryKeys) {
      queryClient.invalidateQueries(queryKeys);
    }
  };

  /*
   * ************** Render *********************
   */
  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            px: {
              md: theme.spacing(3),
              sm: theme.spacing(2),
              xs: theme.spacing(1)
            }
          }}
        >
          <Box
            sx={{
              mt: theme.spacing(6),
              mb: theme.spacing(2)
            }}
          >
            <Typography variant="h2">{t('title')}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Skeleton
              variant="rounded"
              animation="wave"
              width="100%"
              height={theme.spacing(60)}
            />
          </Box>
        </Box>
      </>
    );
  }

  if (isError) {
    handleError(error);
    return (
      <>
        <Box
          sx={{
            px: {
              md: theme.spacing(3),
              sm: theme.spacing(2),
              xs: theme.spacing(1)
            }
          }}
        >
          <Box
            sx={{
              mt: theme.spacing(6),
              mb: theme.spacing(2)
            }}
          >
            <Typography variant="h2">{t('title')}</Typography>
          </Box>
          <Box sx={{ display: 'flex' }}>{t('errorPage')}</Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          px: {
            md: theme.spacing(3),
            sm: theme.spacing(2),
            xs: theme.spacing(1)
          }
        }}
      >
        <Box
          sx={{
            mt: theme.spacing(6),
            mb: theme.spacing(2)
          }}
        >
          <Typography variant="h2">{t('title')}</Typography>
        </Box>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: theme.palette.divider
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChanged}
            aria-label="tabs"
            textColor={theme.palette.mode === 'light' ? 'primary' : 'inherit'}
            TabIndicatorProps={{
              sx: {
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? theme.palette.text.secondary
                    : ''
              }
            }}
            variant={isSmallScreen ? 'scrollable' : 'standard'}
            scrollButtons={isSmallScreen ? true : false}
          >
            <Tab label={t('tabs.qna')} />
            <Tab label={t('tabs.documentStore')} />
          </Tabs>
        </Box>

        <Box
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            padding: '5px',
            mt: 4
          }}
        >
          <CustomTabPanel value={tab} index={0}>
            <QnaTab
              account={account}
              onUpdate={handleUpdate}
              onError={handleError}
            />
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={1}>
            <DocumentStoreTab
              account={account}
              onUpdate={handleUpdate}
              onError={handleError}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default KnowledgeBasePage;
